import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link as ChakraLink,
  Text,
  Divider,
  ButtonProps,
} from "@chakra-ui/react";
import Link from "next/link";
import { Card } from "ui/Card";

const ButtonLogin: React.FC<ButtonProps> = (props) => {
  const { loginWithRedirect } = useAuth0();

  const handleClick = () => {
    loginWithRedirect();
  };

  return (
    <Button flex={1} w="100%" onClick={handleClick} variant="solid" {...props}>
      Login
    </Button>
  );
};
export function LandingPageView() {
  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      backgroundImage={"url('images/gradient.png')"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Flex grow={1} direction="column" justify="center">
        <Card maxW="396" mx="auto" px="0" pt="32px" pb="27px">
          <Box px="32px">
            <Heading
              fontSize="2xl"
              fontWeight="semibold"
              color="gray.900"
              mb="12px"
            >
              Welcome to Accountable
            </Heading>
            <Text fontSize="md" fontWeight="normal" color="#71718E" mb="24px">
              Already have an account? {` `}
            </Text>

            <ButtonLogin mb="32px" />
          </Box>
          <Divider color="gray.200" />
          <Box px="32px">
            <Text fontSize="md" fontWeight="normal" color="#71718E" mt="24px">
              New to Accountable? {` `}
              <Link href="/signup" passHref>
                <ChakraLink color="gray.900" textDecoration="underline">
                  Sign up
                </ChakraLink>
              </Link>
            </Text>
          </Box>
        </Card>
      </Flex>
    </Box>
  );
}
